import React, { CSSProperties, useEffect } from 'react';
import styles from './inde.module.less';
import { Button, Checkbox, Col, Divider, Form, Input, Popover, Row } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import useLogin from './effetcs/useLogin';
import { useLoginStore } from './useLoginStore';
import { isDingtalk } from '@/utils/isDingtalk';
import { isMobile } from '@/utils/isMobile';
import { useSystemStore } from '@/hooks/useSystemConfig';
import { usePreferenceStore } from '@/hooks/usePreferenceConfig';
import IconFont from '@bui/iconfont';
import { useTranslate } from '@/hooks/useTranslate';
import loginBackGroundImage from '@/images/login-background.jpg';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const sysStore = useSystemStore((state) => {
    return {
      init: state.init,
      inited: state.inited,
    };
  });

  const preferenceStore = usePreferenceStore((state) => {
    return {
      langs: state.langs,
      getLangs: state.getLangs,
    };
  });
  const navigate = useNavigate();

  useEffect(() => {
    sysStore.init({ requestMode: 'old' });
    preferenceStore.getLangs();
  }, []);
  // const [logoUrl] = useSystemByKey('logoUrl');
  // const logo = logoUrl || require('@/images/login-logo.png');
  const eimos = require('@/images/EIMOS.png');
  const eimosTitle = require('@/images/logo@2x.webp');
  const [form] = Form.useForm();
  const isDingTalk = isDingtalk();
  const isMobileDevice = isMobile();
  const isDingTalkMobile = isDingTalk && isMobileDevice;

  const t = useTranslate();

  const {
    codeUrl,
    isValidate,
    handleLogin,
    init,
    getCode,
    isUpdate,
    setIsUpdate,
    onConfirm,
    thirdConfig,
  } = useLogin({ form });
  const store = useLoginStore();
  useEffect(() => {
    if (isDingTalkMobile) {
      const dingLoginUrl = `${location.protocol}//${location.host}/eimos-h5/`;
      console.log('检测到钉钉环境，将跳转到钉钉登录', dingLoginUrl);
      const urlSearchParams = new URLSearchParams(location.search);
      const redirectUrl = urlSearchParams.get('service');
      if (redirectUrl) {
        location.href = `${dingLoginUrl}?service=${redirectUrl}`;
        return;
      }
      location.href = dingLoginUrl;
      return;
    }
    init();
  }, []);

  if (!sysStore.inited) {
    return null;
  }

  // const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  //   e.currentTarget.onerror = null;
  //   console.log('logo图片加载错误,图片地址为:', logo);
  //   e.currentTarget.src = require('@/images/login-logo.png');
  // };

  const lang = (localStorage.getItem('eimos:lang') as 'zh-CN' | 'en-US') || 'zh-CN';
  const langLabelMap = {
    'zh-CN': '中文',
    'en-US': 'English',
  };

  const renderLangMenu = () => {
    return (
      <div>
        {preferenceStore.langs.map((item) => {
          return (
            <p
              onClick={() => {
                localStorage.setItem('eimos:lang', item.value);
                location.reload();
                // updateLang({
                //   code: item.value,
                // } as PreferenceConfigType['lang']).then(() => {
                //   location.reload();
                // });
              }}
              className={styles['overlap-a']}
              key={item.value}
            >
              {item.label}
              {lang === item.value ? (
                <IconFont className={styles['checked']} type="icon-check" />
              ) : null}
            </p>
          );
        })}
      </div>
    );
  };

  const renderLangEntry = () => {
    if (!preferenceStore.langs?.length) {
      return null;
    }
    return (
      <Popover
        content={renderLangMenu()}
        title={t('SystemLanguage', '系统语言')}
        overlayClassName={styles['user-info']}
      >
        <div className={styles['right-menu-item']}>
          <IconFont type="icon-diqiu" style={{ fontSize: '20px' }} />
          <span>{langLabelMap[lang]}</span>
        </div>
      </Popover>
    );
  };

  const backGroundStyle: CSSProperties = {
    backgroundImage: `url(${loginBackGroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    position: 'relative',
  };

  const centerImg = require('@/images/login-background2.png');

  // 登录页标题
  const ComponyInfo = (
    <div className={styles['company-info']}>
      <div
        style={{
          fontFamily: 'PingFangSC, PingFang SC',
          fontWeight: 500,
          fontSize: '24px',
          color: '#131E29',
          marginBottom: 13,
        }}
      >
        登录到
      </div>
      <img src={eimos} style={{ width: 127, height: 30, marginBottom: 16 }} alt="EIMOS"></img>
      <div className={styles['subtitle']}>您的企业经营管理业务专家</div>
    </div>
  );

  const isSaas = location.hostname.includes('saas');

  return (
    <div style={{ overflow: 'hidden', height: '100%', minHeight: '600px', ...backGroundStyle }}>
      <img src={centerImg} className={styles['center-img']} />
      <img src={eimosTitle} className={styles['eimos-title-img']} />
      {!isDingTalkMobile && (
        <div className={styles['login']}>
          {isUpdate ? (
            <div className={styles['login-block']}>
              {ComponyInfo}
              <div className={styles['form-wrapper']}>
                <Form
                  className={styles['login-form']}
                  labelCol={{ span: 5 }}
                  form={form}
                  style={{ paddingBottom: '52px' }}
                >
                  <div className={styles['update-form']}>
                    <Form.Item name="username" rules={[{ required: true }]}>
                      <Input
                        prefix={<UserOutlined style={{ color: '#8c8c8c' }} />}
                        type="text"
                        auto-complete="off"
                        placeholder={t('Account', '账号')}
                      />
                    </Form.Item>
                    <Form.Item name="oldPassword" rules={[{ required: true }]}>
                      <Input
                        auto-complete="off"
                        placeholder={t('Password', '密码')}
                        prefix={<LockOutlined style={{ color: '#8c8c8c' }} />}
                      />
                    </Form.Item>
                    <Form.Item
                      tooltip={t(
                        'NewPasswordTooltip',
                        '同时包含大小写字母、数字、特殊字符,且长度不低于8位	',
                      )}
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (getFieldValue('oldPassword') == value && value) {
                              return Promise.reject(
                                new Error(t('SamePasswordTooltip', '不能与原密码一致')),
                              );
                            }
                            if (store.testRule.passwordRule?.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(new Error(store.testRule.errorMsg));
                            }
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder={t('NewPassword', '新密码')}
                        auto-complete="off"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        prefix={<LockOutlined style={{ color: '#8c8c8c' }} />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      dependencies={['newPassword']}
                      rules={[
                        { required: true },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (!getFieldValue('newPassword')) {
                              return Promise.reject(
                                new Error(t('EmptyPasswordTooltip', '新密码不能为空')),
                              );
                            }
                            if (!value || getFieldValue('newPassword') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(t('InconsistentPasswordsTooltip', '两次输入的密码不一致')),
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        type="password"
                        auto-complete="off"
                        placeholder={t('ConfirmPassword', '确认密码')}
                        prefix={<LockOutlined style={{ color: '#8c8c8c' }} />}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col span={24}>
                          <div className={styles['button-group']} style={{ marginTop: 12 }}>
                            <Button
                              type="primary"
                              onClick={onConfirm}
                              style={{ height: 40 }}
                              className={styles['login-button']}
                            >
                              {t('Confirm', '确 认')}
                            </Button>
                            <Button
                              className={styles['cancel-button']}
                              onClick={() => {
                                setIsUpdate(false);
                                form.resetFields();
                                init();
                              }}
                            >
                              {t('Cancle', '取 消')}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          ) : (
            <div className={styles['login-block']}>
              {ComponyInfo}
              <div className={styles['form-wrapper']}>
                <Form className={styles['login-form']} form={form}>
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: t('NotEmptyTooltip', '账号不能为空') }]}
                  >
                    <Input
                      placeholder={t('Account', '账号')}
                      type="text"
                      auto-complete="off"
                      prefix={<UserOutlined style={{ color: '#8c8c8c' }} />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: t('NotEmptyPasswordTooltip', '密码不能为空') },
                    ]}
                  >
                    <Input.Password
                      placeholder={t('Password', '密码')}
                      auto-complete="off"
                      prefix={<LockOutlined style={{ color: '#8c8c8c' }} />}
                      onPressEnter={handleLogin}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                  {isValidate && (
                    <Row>
                      <Col span={16}>
                        <Form.Item
                          name="code"
                          rules={[
                            { required: true, message: t('NotEmptyCAPTCHA', '验证码不能为空') },
                          ]}
                        >
                          <Input
                            placeholder={t('CAPTCHA', '验证码')}
                            auto-complete="off"
                            onPressEnter={handleLogin}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <div className={styles['login-code']}>
                          <img
                            src={codeUrl}
                            className={styles['login-code-img']}
                            onClick={getCode}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col span={12}>
                      <Form.Item name="remember" valuePropName="checked">
                        <Checkbox style={{ fontSize: 14 }}>
                          {t('MemorizePassword', '记住密码')}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <a
                        style={{ float: 'right', fontSize: 14, paddingTop: 5 }}
                        onClick={() => {
                          setIsUpdate(true);
                          form.setFieldValue('password', '');
                        }}
                      >
                        {t('ModifyPassword', '修改密码')}
                      </a>
                    </Col>
                  </Row>

                  <Button
                    size="middle"
                    loading={!!store.loading.handleLogin}
                    className={styles['login-button']}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogin();
                    }}
                  >
                    {!store.loading.handleLogin ? (
                      <span>{t('LogIn', '登 录')}</span>
                    ) : (
                      <span>{t('LoggingIn', '登 录 中')}...</span>
                    )}
                  </Button>

                  {isSaas && (
                    <Button
                      size="middle"
                      loading={!!store.loading.handleLogin}
                      className={styles['login-button']}
                      style={{ marginTop: 10 }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('/login/register');
                      }}
                    >
                      {t('Register', '注册')}
                    </Button>
                  )}

                  {thirdConfig.length > 0 && !isMobileDevice && (
                    <>
                      <Divider className={styles['otherway-login-text']}>
                        {t('OtheWayLog', '	其他方式登录')}
                      </Divider>

                      {/* 三方登录 */}
                      {thirdConfig.map((thirdItem) => {
                        return (
                          <div
                            className={styles['otherway-login']}
                            key={thirdItem.value}
                            onClick={thirdItem.handleOperate}
                          >
                            {thirdItem.icon}
                            <span>{thirdItem.label}</span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Form>
              </div>
            </div>
          )}
          {/* 底部 */}
          <div className={styles['login-footer']}>
            <div>Copyright © 2020-2022 boulderai All Rights Reserved.</div>
          </div>
        </div>
      )}

      {/* 语言设置 */}
      <div className={styles['system-lang']}>{renderLangEntry()}</div>
    </div>
  );
};

export default Login;
