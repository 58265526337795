import IconFont from '@bui/iconfont';
import { ConfigItem } from './type';
import React from 'react';

export const CONFIG_ITEM: ConfigItem[] = [
  {
    label: 'KeyCloak',
    value: 'keycloak',
    configUrl: '/lcp/task/S202401261353455964381_Task20240124000001/list',
    configTitle: 'KeyCloak配置管理',
    order: 4,
    icon: <IconFont type="icon-keycloak" style={{ fontSize: '24px' }} />,
  },
  {
    label: '钉钉',
    value: 'dingding',
    configUrl: '/lcp/task/S202401261353455964381_Task20240122000003/list',
    configTitle: '钉钉配置管理',
    order: 1,
    icon: <IconFont type="icon-dingding" style={{ fontSize: '24px' }} />,
  },
  {
    label: '企微',
    value: 'qiwei',
    configUrl: '',
    configTitle: '',
    order: 2,
  },
  {
    label: '飞书',
    value: 'feishu',
    configUrl: '',
    configTitle: '',
    order: 3,
  },
];
