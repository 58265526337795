import Store, { BaseStore } from 'zustand-store';
import Cookies from 'js-cookie';
import { getCodeImg, getValidateRule, login } from './effetcs/loginService';

interface TestRuleType {
  errorMsg?: string;
  passwordRule?: RegExp;
}
class UseLoginStore extends BaseStore<UseLoginStore> {
  public testRule: TestRuleType = {};
  // public loginUrl = LoginLogo;

  @Store.loading()
  public async handleLogin(userInfo: {
    username: string;
    password: string;
    code: string;
    uuid: string;
  }) {
    const username = userInfo.username.trim();
    const password = userInfo.password;
    const code = userInfo.code;
    const uuid = userInfo.uuid;
    try {
      const res = await login(username, password, code, uuid);
      const path = res?.homePage?.url || '/pro/portal/home';
      const title = res?.homePage?.name || '工作台';
      const newHomeData = JSON.stringify({ name: title, url: path });
      sessionStorage.removeItem('EIMOS_TABS');
      Cookies.set('homePageConfig', newHomeData, { expires: 31 });
      const portalUrl = location.origin;
      const urlSearchParams = new URLSearchParams(location.search);
      let url = urlSearchParams.get('service') || portalUrl;
      try {
        const urlObj = new URL(url);
        if (urlObj.protocol !== location.protocol) {
          urlObj.protocol = location.protocol;
          url = urlObj.toString();
        }
      } catch (e) {
        //
      }
      location.href = url;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public async handleGetCodeImg() {
    const res = await getCodeImg();
    const { img = '', uuid = '' } = res ?? {};
    return {
      img,
      uuid,
    };
  }

  public async getPasswordRegular() {
    try {
      const passwordRuleValue = await getValidateRule();
      this.set((state) => {
        const { errorMsg = '', regularExpression = '' } = passwordRuleValue || {};
        state.testRule = { errorMsg, passwordRule: new RegExp(regularExpression) };
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export const useLoginStore = Store.create(UseLoginStore);
